<template>
    <div class="pt-3 secretary-db-wrapper">
        <div class="accordion" role="tablist">
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.bms-6 variant="secondary" class="accor-header">
                        <span>{{$t('secretaryDb.tea_garden_ms')}}</span>
                        <span class="accordion-toggle-icon">
                            <i class="ri-arrow-down-s-fill"></i>
                        </span>
                    </b-button>
                </b-card-header>
                <b-collapse id="bms-6" visible role="tabpanel">
                    <b-card-body>
                        <div class="section-title">
                            <div class="text">{{$t('secretaryDb.garden_information')}}</div>
                            <div class="line"></div>
                        </div>
                        <b-row class="std-card-list tea-garden-card mt-4">
                            <div class="col-sm-6">
                                <div class="card">
                                    <div class="card-content">
                                        <p class="title"> {{ currentLocale === 'en' ? 'Total Garden' : 'মোট বাগান' }} </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <img class="img-fluid" src="../../../assets/images/tea-garden/garden.png">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="card">
                                    <div class="card-content">
                                        <p class="title"> {{ currentLocale === 'en' ? 'Total Botleaf Factory' : 'মোট বটলিফ কারখানা' }} </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <img class="img-fluid" src="../../../assets/images/tea-garden/karkhana.png">
                                    </div>
                                </div>
                            </div>
                        </b-row>
                        <div class="section-title">
                            <div class="text">{{ currentLocale === 'en' ? 'Fertilizer subsidy' : 'সারের ভর্তুকি' }}</div>
                            <div class="line"></div>
                        </div>
                        <b-row class="mt-4 px-4 ml-4 mr-2">
                            <b-col sm="5">
                                <b-form-group label="Fiscal Year:" label-for="fiscal-year">
                                    <b-form-select
                                    id="fiscal-year"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">2019-2020</b-form-select-option>
                                    <b-form-select-option value="2">2020-2021</b-form-select-option>
                                    <b-form-select-option value="3">2021-2022</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="5">
                                <b-form-group label="Service Name:" label-for="service-name">
                                    <b-form-select
                                    id="service-name"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">Educational Support</b-form-select-option>
                                    <b-form-select-option value="1">National Grant Service</b-form-select-option>
                                    <b-form-select-option value="1">Financial Assistanc</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="2">
                                <div>
                                    <span class="d-block">&nbsp;</span>
                                    <b-button size="sm" variant="secondary">Search</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="std-card-list design-2">
                            <div class="col-sm-4 offset-sm-2">
                                <div class="secretary-db-card purple">
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Processing </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="secretary-db-card blue">
                                    <div class="icon">
                                        <i class="ri-shield-check-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Approved </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                        </b-row>
                        <div class="section-title mt-4">
                            <div class="text">{{ currentLocale === 'en' ? 'Registration and renewal of small tea growers' : 'ক্ষুদ্র চা চাষী নিবন্ধন ও নবায়ন' }}</div>
                            <div class="line"></div>
                        </div>
                            <b-row class="std-card-list design-2">
                                <div class="col-sm-4 offset-sm-2">
                                    <div class="secretary-db-card rad">
                                        <div class="icon">
                                            <i class="ri-stack-fill"></i>
                                        </div>
                                        <div class="card-content">
                                            <p class="title"> {{ currentLocale === 'en' ? 'Total Application' : 'মোট আবেদন' }} </p>
                                            <h2 class="count sale_count"> 23 </h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="secretary-db-card yallow">
                                        <div class="icon">
                                            <i class="ri-shield-check-fill"></i>
                                        </div>
                                        <div class="card-content">
                                            <p class="title"> {{ currentLocale === 'en' ? 'Application Approved' : 'অনুমোদিত আবেদন' }} </p>
                                            <h2 class="count sale_count"> 23 </h2>
                                        </div>
                                    </div>
                                </div>
                            </b-row>
                        <div class="section-title mt-4">
                            <div class="text">{{ currentLocale === 'en' ? 'Education Scholarship' : 'শিক্ষা বৃত্তি' }}</div>
                            <div class="line"></div>
                        </div>
                        <b-row class="std-card-list design-2">
                            <div class="col-sm-4">
                                <div class="secretary-db-card">
                                    <p>{{ currentLocale === 'en' ? 'Scholarship Type' : 'ছাত্রবৃত্তির ধরণ' }}</p>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="secretary-db-card blue">
                                    <div class="icon">
                                        <i class="ri-shield-check-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> {{ currentLocale === 'en' ? 'Total Number of Schools' : 'মোট স্কুল সংখ্যা' }} </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="secretary-db-card green">
                                    <div class="icon">
                                        <i class="ri-shield-check-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> {{ currentLocale === 'en' ? 'Total Number of Students' : 'মোট শিক্ষার্থী সংখ্যা' }} </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                        </b-row>
                        <div class="section-title">
                            <div class="text">{{ currentLocale === 'en' ? 'Tea production' : 'চা উৎপাদন' }}</div>
                            <div class="line"></div>
                        </div>
                        <b-row class="mt-4">
                            <b-col sm="3">
                                <b-form-group label="Fiscal Year:" label-for="year">
                                    <b-form-select
                                    id="year"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">2019-2020</b-form-select-option>
                                    <b-form-select-option value="2">2020-2021</b-form-select-option>
                                    <b-form-select-option value="3">2021-2022</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label="Month:" label-for="month">
                                    <b-form-select
                                    id="month"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">Educational Support</b-form-select-option>
                                    <b-form-select-option value="1">National Grant Service</b-form-select-option>
                                    <b-form-select-option value="1">Financial Assistanc</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="2">
                                <div>
                                    <span class="d-block">&nbsp;</span>
                                    <b-button size="sm" variant="secondary">Search</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="std-card-list design-2">
                            <div class="col-sm-4">
                                <div class="secretary-db-card purple">
                                    <div class="icon">
                                        <i class="ri-shield-check-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> {{ currentLocale === 'en' ? 'Total Made Tea' : 'মোট তৈরি চা' }} </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="secretary-db-card green">
                                    <div class="icon">
                                        <i class="ri-shield-check-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> {{ currentLocale === 'en' ? 'Gross Sales (Tk.)' : 'মোট বিক্রয় (টাকা)' }} </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                        </b-row>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.bms variant="secondary" class="accor-header">
                        <span>{{ currentLocale === 'en' ? 'Bazar Monitoring System' : 'বাজার মনিটরিং সিস্টেম'}}</span>
                        <span class="accordion-toggle-icon">
                            <i class="ri-arrow-down-s-fill"></i>
                        </span>
                    </b-button>
                </b-card-header>
                <b-collapse id="bms" role="tabpanel">
                    <b-card-body>
                        <b-row>
                            <b-col sm="3">
                                <b-form-group label="Fiscal Year:" label-for="fiscal-year">
                                    <b-form-select
                                    id="fiscal-year"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">2019-2020</b-form-select-option>
                                    <b-form-select-option value="2">2020-2021</b-form-select-option>
                                    <b-form-select-option value="3">2021-2022</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label="Month:" label-for="month">
                                    <b-form-select
                                    id="month"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">Grant</b-form-select-option>
                                    <b-form-select-option value="2">Allowance</b-form-select-option>
                                    <b-form-select-option value="3">Stipend</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <div>
                                    <span class="d-block">&nbsp;</span>
                                    <b-button size="sm" variant="secondary">Search</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="std-card-list design-1">
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Service </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-draft-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Application </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-time-line"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Pending </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Processing </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-shield-check-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Approved </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Rejected </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                        </b-row>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-2">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                        <b-button block v-b-toggle.bms-2 variant="secondary" class="accor-header">
                            <span>{{ currentLocale === 'en' ? 'Dealer Management' : 'ডিলার ম্যানেজমেন্ট'}}</span>
                            <span class="accordion-toggle-icon">
                                <i class="ri-arrow-down-s-fill"></i>
                            </span>
                        </b-button>
                    </b-card-header>
                    <b-collapse id="bms-2" role="tabpanel">
                        <b-card-body>
                            <b-row>
                                <b-col sm="3">
                                    <b-form-group label="Fiscal Year:" label-for="fiscal-year">
                                        <b-form-select
                                        id="fiscal-year"
                                        required
                                        >
                                        <b-form-select-option value="select" selected>Select</b-form-select-option>
                                        <b-form-select-option value="1">2019-2020</b-form-select-option>
                                        <b-form-select-option value="2">2020-2021</b-form-select-option>
                                        <b-form-select-option value="3">2021-2022</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="3">
                                    <b-form-group label="Service Type:" label-for="service-type">
                                        <b-form-select
                                        id="service-type"
                                        required
                                        >
                                        <b-form-select-option value="select" selected>Select</b-form-select-option>
                                        <b-form-select-option value="1">Grant</b-form-select-option>
                                        <b-form-select-option value="2">Allowance</b-form-select-option>
                                        <b-form-select-option value="3">Stipend</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="3">
                                    <b-form-group label="Service Name:" label-for="service-name">
                                        <b-form-select
                                        id="service-name"
                                        required
                                        >
                                        <b-form-select-option value="select" selected>Select</b-form-select-option>
                                        <b-form-select-option value="1">Educational Support</b-form-select-option>
                                        <b-form-select-option value="1">National Grant Service</b-form-select-option>
                                        <b-form-select-option value="1">Financial Assistanc</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="3">
                                    <div>
                                        <span class="d-block">&nbsp;</span>
                                        <b-button size="sm" variant="primary">Search</b-button>
                                    </div>
                                </b-col>
                            </b-row>
                            <hr>
                            <b-row class="std-card-list design-2">
                                <div class="col-sm-3">
                                    <div class="secretary-db-card">
                                        <div class="icon">
                                            <i class="ri-stack-fill"></i>
                                        </div>
                                        <div class="card-content">
                                            <p class="title"> Total Service </p>
                                            <h2 class="count sale_count"> 23 </h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="secretary-db-card">
                                        <div class="icon">
                                            <i class="ri-draft-fill"></i>
                                        </div>
                                        <div class="card-content">
                                            <p class="title"> Total Application </p>
                                            <h2 class="count sale_count"> 23 </h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="secretary-db-card">
                                        <div class="icon">
                                            <i class="ri-time-line"></i>
                                        </div>
                                        <div class="card-content">
                                            <p class="title"> Total Pending </p>
                                            <h2 class="count sale_count"> 23 </h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="secretary-db-card">
                                        <div class="icon">
                                            <i class="ri-stack-fill"></i>
                                        </div>
                                        <div class="card-content">
                                            <p class="title"> Total Processing </p>
                                            <h2 class="count sale_count"> 23 </h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="secretary-db-card">
                                        <div class="icon">
                                            <i class="ri-shield-check-fill"></i>
                                        </div>
                                        <div class="card-content">
                                            <p class="title"> Total Approved </p>
                                            <h2 class="count sale_count"> 23 </h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="secretary-db-card">
                                        <div class="icon">
                                            <i class="ri-stack-fill"></i>
                                        </div>
                                        <div class="card-content">
                                            <p class="title"> Total Rejected </p>
                                            <h2 class="count sale_count"> 23 </h2>
                                        </div>
                                    </div>
                                </div>
                            </b-row>
                        </b-card-body>
                    </b-collapse>
            </b-card>
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.bms-3 variant="secondary" class="accor-header">
                        <span>{{ currentLocale === 'en' ? 'Fair Management' : 'মেলা ব্যবস্থাপনা' }}</span>
                        <span class="accordion-toggle-icon">
                            <i class="ri-arrow-down-s-fill"></i>
                        </span>
                    </b-button>
                </b-card-header>
                <b-collapse id="bms-3" role="tabpanel">
                    <b-card-body>
                        <b-row>
                            <b-col sm="3">
                                <b-form-group label="Fiscal Year:" label-for="fiscal-year">
                                    <b-form-select
                                    id="fiscal-year"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">2019-2020</b-form-select-option>
                                    <b-form-select-option value="2">2020-2021</b-form-select-option>
                                    <b-form-select-option value="3">2021-2022</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label="Service Type:" label-for="service-type">
                                    <b-form-select
                                    id="service-type"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">Grant</b-form-select-option>
                                    <b-form-select-option value="2">Allowance</b-form-select-option>
                                    <b-form-select-option value="3">Stipend</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label="Service Name:" label-for="service-name">
                                    <b-form-select
                                    id="service-name"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">Educational Support</b-form-select-option>
                                    <b-form-select-option value="1">National Grant Service</b-form-select-option>
                                    <b-form-select-option value="1">Financial Assistanc</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <div>
                                    <span class="d-block">&nbsp;</span>
                                    <b-button size="sm" variant="primary">Search</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="std-card-list design-3">
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="card-content">
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <p class="title"> Total Service </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="card-content">
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <p class="title"> Total Application </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="card-content">
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <p class="title"> Total Pending </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="card-content">
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <p class="title"> Total Processing </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="card-content">
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <p class="title"> Total Approved </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="card-content">
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <p class="title"> Total Rejected </p>
                                    </div>
                                </div>
                            </div>
                        </b-row>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.bms-4 variant="secondary" class="accor-header">
                        <span>{{ currentLocale === 'en' ? 'Export Trophy' : 'রপ্তানি ট্রফি' }}</span>
                        <span class="accordion-toggle-icon">
                            <i class="ri-arrow-down-s-fill"></i>
                        </span>
                    </b-button>
                </b-card-header>
                <b-collapse id="bms-4" role="tabpanel">
                    <b-card-body>
                        <b-row>
                            <b-col sm="3">
                                <b-form-group label="Fiscal Year:" label-for="fiscal-year">
                                    <b-form-select
                                    id="fiscal-year"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">2019-2020</b-form-select-option>
                                    <b-form-select-option value="2">2020-2021</b-form-select-option>
                                    <b-form-select-option value="3">2021-2022</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label="Service Type:" label-for="service-type">
                                    <b-form-select
                                    id="service-type"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">Grant</b-form-select-option>
                                    <b-form-select-option value="2">Allowance</b-form-select-option>
                                    <b-form-select-option value="3">Stipend</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label="Service Name:" label-for="service-name">
                                    <b-form-select
                                    id="service-name"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">Educational Support</b-form-select-option>
                                    <b-form-select-option value="1">National Grant Service</b-form-select-option>
                                    <b-form-select-option value="1">Financial Assistanc</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <div>
                                    <span class="d-block">&nbsp;</span>
                                    <b-button size="sm" variant="primary">Search</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="std-card-list design-4">
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Service </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-draft-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Application </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-time-line"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Pending </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Processing </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-shield-check-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Approved </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Rejected </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                        </b-row>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.bms-5 variant="secondary" class="accor-header">
                        <span>{{ currentLocale === 'en' ? 'CIP Management' : 'সিআইপি ব্যবস্থাপনা' }}</span>
                        <span class="accordion-toggle-icon">
                            <i class="ri-arrow-down-s-fill"></i>
                        </span>
                    </b-button>
                </b-card-header>
                <b-collapse id="bms-5" role="tabpanel">
                    <b-card-body>
                        <b-row>
                            <b-col sm="3">
                                <b-form-group label="Fiscal Year:" label-for="fiscal-year">
                                    <b-form-select
                                    id="fiscal-year"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">2019-2020</b-form-select-option>
                                    <b-form-select-option value="2">2020-2021</b-form-select-option>
                                    <b-form-select-option value="3">2021-2022</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label="Service Type:" label-for="service-type">
                                    <b-form-select
                                    id="service-type"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">Grant</b-form-select-option>
                                    <b-form-select-option value="2">Allowance</b-form-select-option>
                                    <b-form-select-option value="3">Stipend</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label="Service Name:" label-for="service-name">
                                    <b-form-select
                                    id="service-name"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">Educational Support</b-form-select-option>
                                    <b-form-select-option value="1">National Grant Service</b-form-select-option>
                                    <b-form-select-option value="1">Financial Assistanc</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <div>
                                    <span class="d-block">&nbsp;</span>
                                    <b-button size="sm" variant="primary">Search</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="std-card-list design-5">
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="card-content">
                                        <p class="title"> Total Service </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="card-content">
                                        <p class="title"> Total Application </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <i class="ri-draft-fill"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="card-content">
                                        <p class="title"> Total Pending </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <i class="ri-time-line"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="card-content">
                                        <p class="title"> Total Processing </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="card-content">
                                        <p class="title"> Total Approved </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <i class="ri-shield-check-fill"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="card-content">
                                        <p class="title"> Total Rejected </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                </div>
                            </div>
                        </b-row>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.bms-7 variant="secondary" class="accor-header">
                        <span>{{ currentLocale === 'en' ? 'Tariff Management' : 'ট্যারিফ ব্যবস্থাপনা' }}</span>
                        <span class="accordion-toggle-icon">
                            <i class="ri-arrow-down-s-fill"></i>
                        </span>
                    </b-button>
                </b-card-header>
                <b-collapse id="bms-7" role="tabpanel">
                    <b-card-body>
                        <b-row>
                            <b-col sm="3">
                                <b-form-group label="Fiscal Year:" label-for="fiscal-year">
                                    <b-form-select
                                    id="fiscal-year"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">2019-2020</b-form-select-option>
                                    <b-form-select-option value="2">2020-2021</b-form-select-option>
                                    <b-form-select-option value="3">2021-2022</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label="Service Type:" label-for="service-type">
                                    <b-form-select
                                    id="service-type"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">Grant</b-form-select-option>
                                    <b-form-select-option value="2">Allowance</b-form-select-option>
                                    <b-form-select-option value="3">Stipend</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label="Service Name:" label-for="service-name">
                                    <b-form-select
                                    id="service-name"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">Educational Support</b-form-select-option>
                                    <b-form-select-option value="1">National Grant Service</b-form-select-option>
                                    <b-form-select-option value="1">Financial Assistanc</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <div>
                                    <span class="d-block">&nbsp;</span>
                                    <b-button size="sm" variant="primary">Search</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="std-card-list design-2">
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Service </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-draft-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Application </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-time-line"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Pending </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Processing </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-shield-check-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Approved </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Rejected </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                        </b-row>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.bms-8 variant="secondary" class="accor-header">
                        <span>{{ currentLocale === 'en' ? 'Registered Entity' : 'নিবন্ধিত সত্তা' }}</span>
                        <span class="accordion-toggle-icon">
                            <i class="ri-arrow-down-s-fill"></i>
                        </span>
                    </b-button>
                </b-card-header>
                <b-collapse id="bms-8" role="tabpanel">
                    <b-card-body>
                        <b-row>
                            <b-col sm="3">
                                <b-form-group label="Fiscal Year:" label-for="fiscal-year">
                                    <b-form-select
                                    id="fiscal-year"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">2019-2020</b-form-select-option>
                                    <b-form-select-option value="2">2020-2021</b-form-select-option>
                                    <b-form-select-option value="3">2021-2022</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label="Service Type:" label-for="service-type">
                                    <b-form-select
                                    id="service-type"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">Grant</b-form-select-option>
                                    <b-form-select-option value="2">Allowance</b-form-select-option>
                                    <b-form-select-option value="3">Stipend</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label="Service Name:" label-for="service-name">
                                    <b-form-select
                                    id="service-name"
                                    required
                                    >
                                    <b-form-select-option value="select" selected>Select</b-form-select-option>
                                    <b-form-select-option value="1">Educational Support</b-form-select-option>
                                    <b-form-select-option value="1">National Grant Service</b-form-select-option>
                                    <b-form-select-option value="1">Financial Assistanc</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <div>
                                    <span class="d-block">&nbsp;</span>
                                    <b-button size="sm" variant="primary">Search</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="std-card-list design-4">
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Service </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-draft-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Application </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-time-line"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Pending </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Processing </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-shield-check-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Approved </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="secretary-db-card">
                                    <div class="icon">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                    <div class="card-content">
                                        <p class="title"> Total Rejected </p>
                                        <h2 class="count sale_count"> 23 </h2>
                                    </div>
                                </div>
                            </div>
                        </b-row>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
        }
    },
    computed: {
         currentLocale () {
            return this.$i18n.locale
        }
    }
}
</script>
<style scoped>
.section-title{
    height: 18px;
    display: flex;
}
.section-title .line{
  width:100%;
  border-bottom:2px solid #ccc;
}
.section-title .text{
  position: relative;
  top:0;
  padding:5px;
  font-weight: bold;
  text-wrap: nowrap;
}

.secretary-db-wrapper .accor-header{
    overflow-anchor: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.secretary-db-wrapper .accordion-toggle-icon {
    margin-right: 0.5rem;
    transition: transform 0.3s ease;
}
.secretary-db-wrapper .accordion-toggle-icon i{
    font-size: 30px;
    line-height: 30px;
    display: block;
}

.secretary-db-wrapper .not-collapsed span i{
    transform: rotate(180deg);
}
.secretary-db-wrapper .secretary-db-card{
    margin-top: 20px;
    padding: 10px;
    display: flex;
    border-radius: 5px;
}
.secretary-db-wrapper .secretary-db-card .icon{
    color: #fff;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    width: 34px;
    height: 34px;
    padding: 5px;
    border-radius: 5px;
}
.secretary-db-wrapper .tea-garden-card .card{
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    background: linear-gradient(67deg, #2DDD8B 51.69%, #45DFA3 51.7%);
}
.secretary-db-wrapper .tea-garden-card .card .icon{
    padding: 0.2rem;
    border-radius: 5px;
    background-color: #fff;
}
.secretary-db-wrapper .tea-garden-card .card .icon img{
    max-width: 100px;
    min-height: 67px;
    border-radius: 5px;
}
.secretary-db-wrapper .std-card-list.design-1 .secretary-db-card .icon{
    margin-top: -25px;
    margin-right: 5px;
}
.secretary-db-wrapper .std-card-list.design-2 .secretary-db-card .icon{
    margin-top: 12px;
    margin-right: 5px;
}
.secretary-db-wrapper .std-card-list.design-3 .secretary-db-card{
    flex-direction: column;
    align-items: center;
}
.secretary-db-wrapper .std-card-list.design-3 .secretary-db-card .icon{
    width: 100%;
    height: auto;
}
.secretary-db-wrapper .std-card-list.design-3 .secretary-db-card .icon .title{
    font-size: 15px;
}
.secretary-db-wrapper .std-card-list.design-4 .secretary-db-card{
    flex-direction: column;
    align-items: center;
}
.secretary-db-wrapper .std-card-list.design-5 .secretary-db-card{
    justify-content: space-between;
    align-items: center;
}
.secretary-db-wrapper .std-card-list.design-4 .secretary-db-card .card-content .count{
    text-align: center;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.yallow{
    background-color: #fff4e4;
    border: 2px solid #ffb554;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.yallow .icon{
    background-color: #ffb554;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.rad{
    background-color: #ffe8e8;
    border: 2px solid #FF5454;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.rad .icon{
    background-color: #FF5454;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.green{
    background-color: #f4ffda;
    border: 2px solid #83ce20;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.green .icon{
    background-color: #83ce20;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.sky{
    background-color: #d7feff;
    border: 2px solid #09a8cf;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.sky .icon{
    background-color: #09a8cf;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.blue{
    background-color: #cce8ff;
    border: 2px solid #095fcf;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.blue .icon{
    background-color: #095fcf;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.purple{
    background-color: #e2ccff;
    border: 2px solid #5f09cf;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.purple .icon{
    background-color: #5f09cf;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.pink{
    background-color: #ffd5ec;
    border: 2px solid #cf0983;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.pink .icon{
    background-color: #cf0983;
}
</style>
